import React from 'react';
import styled from 'styled-components';

export const ErrorPage = () => (
  <ErrorWrapper>
    <h3>Oops 😬</h3>
    <p>Something went wrong.</p>
  </ErrorWrapper>
)

const ErrorWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;