import styled from 'styled-components';

export const MoneyCell = styled.td`
  text-align: right;
  padding-right: 10px;
`;

export const DateCell = styled.td`
  white-space: nowrap;
  padding-right: 10px;
`;


export const DefaultTable = styled.table`
  font-size: 10px;
  width: 100%;
`