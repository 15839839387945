import React from 'react';
import styled from 'styled-components';
import {Spinner} from './Spinner';

export const LoadingPage = () => (
    <EmptyWrapper>
        <Spinner style={{width: "50px", height: "50px"}}/>
    </EmptyWrapper>
);

const EmptyWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
