export const moneyFormat = (input, opts = {}) => {
  if (typeof input === 'undefined' || input === null) {
    return '-';
  }

  // clean up input
  if (typeof input === 'string') input = parseInt(input)
  if (Object.is(input, -0)) input = 0

  // handle decimals
  // @ts-ignore
  let { decimals = 2 } = opts;
  input = input.toFixed(decimals)


  // for our purposes this US not AU, as the function is internationalised and we don't want A$10.50
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: decimals,
  });

  return formatter.format(input);
};
