import React, {useCallback, useContext, useEffect, useState} from 'react';
import styled from 'styled-components';

import {EmptyPage} from '../components/EmptyPage';
import {ErrorPage} from '../components/ErrorPage';
import {LoadingPage} from '../components/LoadingPage';
import {subscribeToFront} from "../lib/front.wrapper";
import {WebViewContext} from '@frontapp/plugin-sdk/dist/webViewSdkTypes';
import {SingleConversationContext} from '@frontapp/ui-bridge/dist/internal/contextTypesV2';
import {AccountSection} from '../components/sections/AccountSection';
import {ApplicationSection} from '../components/sections/ApplicationSection';
import {AttachmentInfo, FrontCustomerInfo} from '../components/lib/types';
import {fetchEdstartData, LoadingStatus} from '../lib/loading';
import {AuthSecret} from '../lib/auth';
import {PluginContext} from '../lib/plugin.context';
import {ApplicationTagId} from '@frontapp/plugin-sdk';

export const FrontApp = () => {
    const [attachments, setAttachments] = useState<AttachmentInfo[]>([]);
    const loadedState = useEdstartCustomerInfo();
    const pluginContext = useContext(PluginContext);
    useEffect(() => {
        subscribeToFront(async (context) => {
            console.log('[Front plugin]: New context');
            pluginContext.openUrl = context.openUrl;
            pluginContext.tagConversation = null;
            const conversationContext = isSingleConversation(context);
            loadedState.clear();
            setAttachments([]);
            if (!conversationContext) {
                console.log('[Front plugin]: No single conversation');
                return;
            }
            const conversation = conversationContext.conversation;
            pluginContext.tagConversation = (tagId) => {
                console.log(`Tagging conversation ${conversation.id} with ${tagId}`);
                conversationContext.tag([tagId as ApplicationTagId]);
            }
            loadedState.setEmail(conversation.recipient.handle);
            conversationContext.listMessages().then(messages => {
                const attachmentMap = {};
                messages.results.forEach(msg => {
                    msg.content?.attachments?.forEach(attachment => {
                        attachmentMap[attachment.id] = ({
                            attachment,
                            conversationId: conversation.id,
                            msgId: msg.id
                        });
                    })
                })
                const attachments = Object.keys(attachmentMap).map(id => attachmentMap[id]);
                setAttachments(attachments);
            });
        });
    }, []);

    const {customerInfo} = loadedState;
    if (loadedState.status === "error") {
        return <ErrorPage/>
    }
    // Only show a full loading page if we're loading a fresh context
    else if (loadedState.status === "loading" && !customerInfo) {
        return <LoadingPage />;
    }
    if (!customerInfo) {
        return <EmptyPage />;
    }
    const {accounts, applications} = customerInfo;
    if (accounts.length === 0 && applications.length === 0) {
        return <EmptyPage />;
    }

    return (
        <Container>
            <AppContent>
                <AccountSection
                    accounts={accounts}
                    drawdowns={customerInfo.drawdowns}
                    invoices={customerInfo.invoices}
                    attachments={attachments}
                    refresh={loadedState.refresh}
                />
                <ApplicationSection applications={applications}/>
            </AppContent>
        </Container>
    );
}

function isSingleConversation(context: WebViewContext): SingleConversationContext | null {
    switch(context.type) {
        case 'noConversation':
            // console.log('No conversation selected');
            break;
        case 'singleConversation':
            // console.log('Selected conversation:', context.conversation);
            return context as SingleConversationContext;
        case 'multiConversations':
            // console.log('Multiple conversations selected', context.conversations);
            break;
        default:
            // console.error(`Unsupported context type: ${context.type}`);
            break;
    }
    return null;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
`;

const AppContent = styled.div`
  display: flex;
  overflow: scroll;
  flex-direction: column;
  flex-grow: 1;
`;


interface CustomerInfoStore {
    customerInfo?: FrontCustomerInfo;
    setEmail(email: string);
    status: LoadingStatus;
    clear();
    refresh();
}

function useEdstartCustomerInfo(): CustomerInfoStore {
    const [email, setEmailState] = useState<string>(null);
    const [status, setLoadingStatus] = useState<LoadingStatus>("loading");
    const [customerInfo, setCustomerInfo] = useState<FrontCustomerInfo>(null);
    const refresh = useCallback(async () => {
        setLoadingStatus("loading");
        try {
            console.log('Loading customer data from Edstart platform')
            const serverResponse = await fetchEdstartData<FrontCustomerInfo>(`?email=${email}&authSecret=${AuthSecret}`);
            setCustomerInfo(serverResponse.data);
            setLoadingStatus("idle");
        } catch (err) {
            console.error(err)
            setLoadingStatus("error");
        }
    }, [email]);
    useEffect(() => {
        refresh().then();
    }, [email])

    return {
        customerInfo,
        clear() {
            setLoadingStatus("loading");
            setCustomerInfo(null);
            setEmailState(null);
        },
        setEmail(email: string) {
            const cleanEmail = encodeURIComponent(email);
            setEmailState(cleanEmail);
        },
        status,
        refresh,
    }
}