import React, {FC, useCallback, useEffect, useState} from 'react';
import {Card} from './Card';
import {Description} from './Description';
import {moneyFormat} from './lib/formatting';
import {
    AccountInfo,
    AttachmentInfo,
    Callback,
    CombinedAttachmentInfo,
    DrawdownInfo,
    InvoiceInfo,
    InvoiceResults,
} from './lib/types';
import {AdminLink} from './Link';
import {DrawdownSection} from './sections/DrawdownSection';
import {InvoiceSection} from './sections/InvoiceSection';
import {AttachmentSection} from './sections/AttachmentSection';
import {fetchEdstartData, LoadingStatus} from '../lib/loading';
import {AuthSecret} from '../lib/auth';
import {RefreshButton} from './common/RefreshButton';

interface AccountCardProps {
    account: AccountInfo;
    drawdowns: DrawdownInfo[];
    invoices: InvoiceInfo[];
    attachments: AttachmentInfo[];
    refresh: Callback;
}

export const AccountCard: FC<AccountCardProps> = ({ account , drawdowns, attachments, refresh}) => {
    const invoiceStore = useInvoices();
    useEffect(() => {
        invoiceStore.setAccountId(account.id);
    }, [account.id]);
    const loading = invoiceStore.status === "loading";
    const combinedAttachments = combineAttachmentsAndInvoices(invoiceStore.invoicesInfo, attachments);
    return (
        <Card title={
            <AdminLink type="accounts" id={account.id}>Account - {account.shortId}</AdminLink>} button={
                <RefreshButton
                    onClick={() => {
                        invoiceStore.refresh();
                        refresh();
                    }}
                    loading={loading}
                />
        }>
            <Description label="Product">{renderProduct(account.product)}</Description>
            <Description label="Status">{account.status}</Description>
            <Description label="Credit Limit">{moneyFormat(account.creditLimit)}</Description>
            <Description label="Balance">{moneyFormat(account.balance)}</Description>
            <Description label="Opened">{account.openDate}</Description>
            {drawdowns.length > 0 && <DrawdownSection drawdowns={drawdowns}/>}
            {invoiceStore.hasData && <InvoiceSection invoicesInfo={invoiceStore.invoicesInfo}/>}
            {combinedAttachments.length > 0 && <AttachmentSection attachments={combinedAttachments} accountId={account.id} afterAttach={invoiceStore.refresh}/>}
        </Card>
    );
}

function renderProduct(product: string): string {
    if (product === 'PayPlus') {
        return 'Plus (Funded)';
    }
    if (product === 'Passthrough') {
        return 'Plus (Admin)';
    }
    return product;
}

function combineAttachmentsAndInvoices(invoicesInfo: InvoiceResults, attachments: AttachmentInfo[]): CombinedAttachmentInfo[] {
    if (!invoicesInfo?.invoices?.length) {
        return attachments;
    }
    const fileIdMap = {};
    invoicesInfo.invoices.forEach(i => {
        if (i.frontFileId) {
            fileIdMap[i.frontFileId] = i.id;
        }
    });
    return attachments.map(a => ({...a, invoiceId: fileIdMap[a.attachment.id]}));
}

interface InvoiceStore {
    hasData: boolean;
    invoicesInfo: InvoiceResults;
    refresh();
    setAccountId(accountId: string);
    status: LoadingStatus;
}

function useInvoices(): InvoiceStore {
    const [accountId, setAccountId] = useState<string>(null);
    const [status, setLoadingStatus] = useState<LoadingStatus>(null);
    const [invoicesInfo, setInvoicesInfo] = useState<InvoiceResults>(null);
    const refresh = useCallback(async () => {
        if (!accountId) {
            return;
        }
        setLoadingStatus("loading");
        try {
            console.log("Fetching invoices...")
            const serverResponse = await fetchEdstartData<InvoiceResults>(`/invoices?accountId=${accountId}&authSecret=${AuthSecret}`);
            setInvoicesInfo(serverResponse.data);
            if (serverResponse.data.processing) {
                console.log('Is processing, scheduling another fetch')
                setTimeout(refresh , 3000);
            }
            setLoadingStatus("idle");
        } catch (err) {
            console.error(err)
            setLoadingStatus("error");
        }
    }, [accountId]);
    useEffect(() => {
        refresh().then();
    }, [accountId])

    return {
        hasData: (invoicesInfo?.invoices?.length ?? 0) > 0,
        refresh: () => refresh().then(),
        status,
        invoicesInfo,
        setAccountId
    }
}

