import React, {FC, ReactElement} from 'react';
import styled from 'styled-components';

interface CardSectionProps {
    title: string | ReactElement;
}

export const CardSection: FC<CardSectionProps> = ({children, title}) => {
    return <div>
        <OuterTitleStyle>
            <Line/>
            <TitleText>{title}</TitleText>
            <Line/>
        </OuterTitleStyle>
        <div>{children}</div>
    </div>;
}

const Line = styled.div`
  border-top: 1px solid #bbb;
  min-width: 5px;
  margin-top: 10px;
  flex-grow: 1;
`;

const TitleText = styled.div`
  margin: 0 10px;
`;

const OuterTitleStyle = styled.div`
  margin: 20px 0 10px;
  display: flex;
`;