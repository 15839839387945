import React, {FC} from 'react';
import {InvoiceInfo, InvoiceResults} from '../lib/types';
import {CardSection} from '../CardSection';
import {AdminLink} from '../Link';
import {moneyFormat} from '../lib/formatting';
import {DateCell, DefaultTable, MoneyCell} from '../common/tables';
import {SmallSpinner} from '../Spinner';

export const InvoiceSection: FC<{invoicesInfo: InvoiceResults}> = ({invoicesInfo}) => {
    return (
        <CardSection title="Invoices">
            <DefaultTable>
                <tbody>{invoicesInfo.invoices.map(invoice => (<InvoiceRow key={invoice.id} invoice={invoice}/>))}</tbody>
            </DefaultTable>
        </CardSection>
    );
}

const InvoiceRow: FC<{invoice: InvoiceInfo}> = ({invoice}) => (
    <tr>
        <DateCell><AdminLink type="customer-invoices" id={invoice.id}>{invoice.dueDate ?? '(No due date)'}</AdminLink></DateCell>
        <MoneyCell>{moneyFormat(invoice.amount)}</MoneyCell>
        <td>{invoice.status}</td>
        <td>{invoice.processing && <SmallSpinner/>}</td>
    </tr>
);
