export function reloadOnChanges(component: string) {
    const shouldReload = shouldReloadOnChanges();
    if (!shouldReload) return;
    console.log(`[${component}]: Enabling reload on changes (${shouldReload})`)
    new EventSource('/esbuild').addEventListener('change', () => {
        console.log(`[${component}]: Code changed, reloading...`)
        location.reload();
    });
}
function shouldReloadOnChanges() : string | null {
    if (location.hostname === 'localhost' || location.hostname === 'edstart-front-dev.au.ngrok.io') {
        return `host: ${location.hostname}`
    }
    if (window['Cypress']) {
        return 'Cypress running';
    }
    return null;

}