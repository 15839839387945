import React from 'react';
import ReactDOM from 'react-dom';
import {reloadOnChanges} from './main/lib/dev.reload';
import {FrontApp} from './main/routes/FrontApp';
import {EdstartVersion} from './main/config/version';
import {getCookie} from '../../admin/src/js/base/cookies';

console.log("[Front plugin]: Loading...", EdstartVersion);
window['edstartVersion'] = EdstartVersion;
// console.log('[Front plugin]', getCookie())
ReactDOM.render(React.createElement(FrontApp), document.getElementById('root'));
reloadOnChanges('Front plugin');
