import React, {FC} from 'react';
import {ApplicationInfo} from '../lib/types';
import {ApplicationCard} from '../ApplicationCard';


interface ApplicationSectionProps {
  applications: ApplicationInfo[];
}
export const ApplicationSection: FC<ApplicationSectionProps> = ({applications}) => {
  if (!applications.length) {
    return null;
  }
  return <>{ applications.map(application => <ApplicationCard key={application.id} application={application} />) } </>;
}

