// Spinner from: https://tobiasahlin.com/spinkit/
import styled, {keyframes} from 'styled-components';
import React, {CSSProperties, FC} from 'react';

export const Spinner: FC<{style: CSSProperties}> = ({style}) => (
    <SpinnerStyle style={style}>
        <ChaseDot/>
        <ChaseDot/>
        <ChaseDot/>
        <ChaseDot/>
        <ChaseDot/>
        <ChaseDot/>
    </SpinnerStyle>
);
export const SmallSpinner = () => <Spinner style={{width: "13px", height: "13px"}}/>;

const rotateChase = keyframes`
  100% { transform: rotate(360deg); }
`;

const rotateChaseDot = keyframes`
  80%, 100% { transform: rotate(360deg); }
`;
const rotateChaseDotBefore = keyframes`
  50% {
    transform: scale(0.4);
  } 100%, 0% {
      transform: scale(1.0);
    }
`;

const SpinnerStyle = styled.div`
  position: relative;
  animation: ${rotateChase} 2.5s infinite linear both;
`;

const ChaseDot = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: ${rotateChaseDot} 2.0s infinite ease-in-out both;
  &:before {
    content: '';
    display: block;
    width: 25%;
    height: 25%;
    background-color: #333;
    border-radius: 100%;
    animation: ${rotateChaseDotBefore} 2.0s infinite ease-in-out both;
  }
  &:nth-child(1) { animation-delay: -1.1s; }
  &:nth-child(2) { animation-delay: -1.0s; }
  &:nth-child(3) { animation-delay: -0.9s; }
  &:nth-child(4) { animation-delay: -0.8s; }
  &:nth-child(5) { animation-delay: -0.7s; }
  &:nth-child(6) { animation-delay: -0.6s; }
  &:nth-child(1):before { animation-delay: -1.1s; }
  &:nth-child(2):before { animation-delay: -1.0s; }
  &:nth-child(3):before { animation-delay: -0.9s; }
  &:nth-child(4):before { animation-delay: -0.8s; }
  &:nth-child(5):before { animation-delay: -0.7s; }
  &:nth-child(6):before { animation-delay: -0.6s; }
`;

