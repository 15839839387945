import {useState} from 'react';
import styled from 'styled-components';

export function useToggle(): [boolean, () => void] {
    const [value, setValue] = useState(false);
    return [value, () => setValue(existing => !existing)];
}

export const ToggleStyle = styled.a`
  text-align: right;
  font-size: 10px;
  font-weight: bold;
  text-decoration: none;
  color: black;
  display: block;
  margin-top: 10px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
