import React, {FC} from 'react';
import {DrawdownInfo} from '../lib/types';
import {CardSection} from '../CardSection';
import {AdminLink} from '../Link';
import {moneyFormat} from '../lib/formatting';
import {DateCell, DefaultTable, MoneyCell} from '../common/tables';
import {partition} from 'lodash';
import {jsDateToIsoDate} from '../../lib/dates';
import {ToggleStyle, useToggle} from '../common/Toggle';

export const DrawdownSection: FC<{drawdowns: DrawdownInfo[]}> = ({drawdowns}) => {
    const [showOld, toggleShowOld] = useToggle();
    const {recent, old} = splitDrawdownList(drawdowns);
    const drawdownsToShow = showOld ? [...recent, ...old] : recent;
    return (
        <CardSection title="Drawdowns">
            <DefaultTable>
                <tbody>{drawdownsToShow.map(drawdown => (<DrawdownRow key={drawdown.id} drawdown={drawdown}/>))}</tbody>
            </DefaultTable>
            { old.length > 0 && <ToggleStyle data-testid="drawdown-expander" onClick={() => toggleShowOld()}>{showOld ? 'Hide' : 'Show'} {old.length} old drawdowns</ToggleStyle>}
        </CardSection>
    );
}


interface DrawdownSplit {
    recent: DrawdownInfo[];
    old: DrawdownInfo[];
}

function splitDrawdownList(drawdowns: DrawdownInfo[]): DrawdownSplit {
    const now = new Date();
    // - 90 days
    const firstDrawdownDate = jsDateToIsoDate(new Date(now.getTime() - 1000 * 3600 * 24 * 90));
    const sorted = sortDescending(drawdowns);
    const res = partition(sorted, dd => dd.date >= firstDrawdownDate);
    return {
        recent: res[0],
        old: res[1]
    };
}


function sortDescending(drawdowns: DrawdownInfo[]): DrawdownInfo[] {
    const sortedDrawdowns = drawdowns;
    sortedDrawdowns.sort(compareDrawdowns);
    return sortedDrawdowns;
}

function compareDrawdowns(a: DrawdownInfo, b: DrawdownInfo): number {
    const date1 = a.date ?? '';
    const date2 = b.date ?? '';
    return -date1.localeCompare(date2);
}

const DrawdownRow: FC<{drawdown: DrawdownInfo}> = ({drawdown}) => (
    <tr>
        <DateCell><AdminLink type="drawdowns" id={drawdown.id}>{drawdown.date ?? '(No date)'}</AdminLink></DateCell>
        <MoneyCell>{moneyFormat(drawdown.amount)}</MoneyCell>
        <td>{drawdown.status}</td>
    </tr>
);