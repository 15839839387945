import React, {FC} from 'react';
import {Card} from './Card';
import {Description} from './Description';
import {ApplicationInfo} from './lib/types';
import {AdminLink} from './Link';


interface ApplicationCardProps {
    application: ApplicationInfo;
}

export const ApplicationCard: FC<ApplicationCardProps> = ({ application }) => (
    <Card title={<AdminLink type="applications" id={application.id}>Application - {applicationTitle(application)}</AdminLink>}>
        <Description label="Status">{application.status}</Description>
        <Description label="Product">{application.productType}</Description>
        <Description label="Submitted">{new Date(application.submitted).toDateString()}</Description>
    </Card>
)

function applicationTitle(application: ApplicationInfo): string {
    if (!application.applicants.length) {
        return '-'
    }
    const applicant = application.applicants[0].details;
    return `${applicant.firstName} ${applicant.lastName}`
}