import React from 'react';
import styled from 'styled-components';
import Empty from '../static/empty.svg';

export const EmptyPage = () => (
    <EmptyWrapper>
        <Empty style={{width: 150}}/>
        <p>No Data</p>
    </EmptyWrapper>
);

const EmptyWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
