import React, {FC, useContext, useState} from 'react';
import {CardSection} from '../CardSection';
import {DefaultTable} from '../common/tables';
import {AdminLink, Link, LinkStyle} from '../Link';
import Axios from 'axios';
import {networkConfig} from '../../config/network';
import {AttachmentInfo, Callback, CombinedAttachmentInfo} from '../lib/types';
import {SmallSpinner} from '../Spinner';
import {PluginContext} from '../../lib/plugin.context';
import {partition} from 'lodash';
import {ToggleStyle, useToggle} from '../common/Toggle';
import {Card} from '../Card';

interface AttachmentSectionProps {
    accountId: string;
    attachments: CombinedAttachmentInfo[];
    afterAttach: Callback;
}

export const AttachmentSection: FC<AttachmentSectionProps> = ({attachments, accountId, afterAttach}) => {
    const [showOther, toggleShowOthers] = useToggle();
    const [pdfs, others] = partition(attachments, a => isPdf(a.attachment.name));
    const showToggle = pdfs.length > 0;
    const items = showToggle && !showOther ? pdfs : attachments;
    return (
        <CardSection title="Attachments">
            <DefaultTable>
                <tbody>{items.map(info => (<AttachmentRow
                    key={info.attachment.id}
                    attachmentInfo={info}
                    accountId={accountId}
                    afterAttach={afterAttach}
                    />))}</tbody>
            </DefaultTable>
            {showToggle && <ToggleStyle onClick={toggleShowOthers}>{showOther ? 'Hide' : 'Also show'} {others.length} non-pdf items</ToggleStyle>}
        </CardSection>
    );
}

function isPdf(filename: string): boolean {
    return /\.pdf$/i.test(filename);
}

interface AttachmentRowProps {
    accountId: string;
    afterAttach: Callback;
    attachmentInfo: CombinedAttachmentInfo;
}

const AttachmentRow: FC<AttachmentRowProps> = ({attachmentInfo, accountId, afterAttach}) => {
    return (
        <tr>
            <td>{attachmentInfo.attachment.name}</td>
            <td><ActionCell attachmentInfo={attachmentInfo} accountId={accountId} afterAttach={afterAttach}/></td>
        </tr>
    );
}

const ActionCell: FC<AttachmentRowProps> = ({attachmentInfo, accountId, afterAttach}) => {
    const [loading, setLoading] = useState(false);
    const pluginContext = useContext(PluginContext);
    if (loading) {
        return <SmallSpinner/>;
    }
    if (attachmentInfo.invoiceId) {
        return <AdminLink type="customer-invoices" id={attachmentInfo.invoiceId}>Invoice created</AdminLink>;
    }
    return (
        <LinkStyle data-testid="process-attachment" onClick={() => {
            attachFileToAccount(attachmentInfo, accountId).then(() => {
                pluginContext.tagConversation('tag_zx4sp'); // Invoice created
                afterAttach();
                setLoading(false);
            });
            setLoading(true);
        }}>Process as invoice</LinkStyle>
    );
}


async function attachFileToAccount(info: AttachmentInfo, accountId: string) {
    const authSecret = new URLSearchParams(window.location.search).get('auth_secret');
    const url = `${networkConfig.adminServerUrl}/api/external/front/invoices?authSecret=${authSecret}`;
    await Axios.post(url, {
        frontFileId: info.attachment.id,
        frontMsgId: info.msgId,
        frontConversationId: info.conversationId,
        accountId
    });
}