import React, {FC} from 'react';
import {AccountInfo, AttachmentInfo, Callback, DrawdownInfo, InvoiceInfo} from '../lib/types';
import {AccountCard} from '../AccountCard';

interface AccountSectionProps {
    accounts: AccountInfo[];
    drawdowns: DrawdownInfo[];
    invoices: InvoiceInfo[];
    attachments: AttachmentInfo[];
    refresh: Callback;
}

export const AccountSection: FC<AccountSectionProps> = ({accounts, drawdowns, invoices, attachments, refresh}) => {
    if (!accounts.length) {
        return null;
    }
    return <>{ accounts.map(account => <AccountCard
        key={account.id}
        account={account}
        drawdowns={drawdowns.filter(d => d.accountId === account.id)}
        invoices={invoices}
        attachments={attachments}
        refresh={refresh}
    />) } </>;
}