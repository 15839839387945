import React, {FC} from "react";
import Reload from '../../static/reload.svg';
import styled, {keyframes} from 'styled-components';
import {Callback} from '../lib/types';

interface RefreshButtonProps {
    loading?: boolean;
    onClick: Callback;
}
export const RefreshButton: FC<RefreshButtonProps> = ({onClick, loading = false}) => (
    <ButtonStyle type="button" onClick={onClick}>
        <ButtonInner className={loading ? 'spin' : ''}>
            <Reload style={{width: "20px", height: "20px"}}/>
        </ButtonInner>
    </ButtonStyle>
)

const rotate = keyframes`
  100% { transform: rotate(360deg); }
`;

const ButtonInner = styled.div`
  &.spin {
    animation: ${rotate} 2.5s infinite linear;
  }
`;

const ButtonStyle = styled.button`
  background: none;
  cursor: pointer;
  border: 1px solid #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
  color: #555;
  &:hover {
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f7f7f7;
  }
`


