import React, {FC, ReactElement} from 'react';
import styled from 'styled-components';

interface CardProps {
    title: string | ReactElement;
    button?: ReactElement;
}
export const Card: FC<CardProps> = ({children, button, title}) => {
    return (
    <Container>
      <Head>
          <Title data-testid="card-title">{title}</Title>
          {button}
      </Head>
      <Body>{children}</Body>
    </Container>
  );
}

const Container = styled.div`
  background-color: #fff;
  border-radius: 2px;
  border: 1px solid #e8e8e8;
  margin: 8px;
`;

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
  padding: 10px 24px;
`;

const Title = styled.div`
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  a {
    color: rgba(0, 0, 0, 0.85);
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
`;

const Body = styled.div`
  padding: 24px;
`;

