import Front from '@frontapp/plugin-sdk';
import {WebViewContext} from '@frontapp/plugin-sdk/dist/webViewSdkTypes';
import {ApplicationMessageList} from '@frontapp/ui-bridge/dist/internal/messageTypesV2';
import {ApplicationAttachmentId} from '@frontapp/ui-bridge/dist/internal/idTypesV2';

export function subscribeToFront(callback: (context: WebViewContext) => void) {
    const params = new URLSearchParams(window.location.search)
    const emulatorMode = params.get("mode") === 'emulation';
    console.log('Subscribing to Front' + (emulatorMode ? ' (emulation)' : ''))
    if (emulatorMode) {
        setTimeout(() => {
            console.log('Simulating context change');
            callback({
                type: 'singleConversation',
                // @ts-ignore
                conversation: {
                    recipient: {
                        handle: "r1",
                        name: "",
                        contact: undefined,
                        type: ""
                    }
               },
               async listMessages(): Promise<ApplicationMessageList> {
                   // @ts-ignore
                   return {
                       results: [{
                           content: {
                               attachments: [
                                   // @ts-ignore
                                   {
                                       id: 'front_attachment_1' as ApplicationAttachmentId,
                                       name: 'school_fees.pdf',
                                   }
                               ]
                           }
                       }]
                   };
               }
            });
        }, 100)
    } else {
        Front.contextUpdates.subscribe(callback);
    }
}
