const settings = {
    'dev': {
        adminServerUrl: 'http://localhost:8019',
        adminWebUrl: 'http://localhost:8011',
    },
    'beta': {
        adminServerUrl: 'https://beta-admin.edstart.com.au',
        adminWebUrl: 'https://beta-admin.edstart.com.au',
    },
    'prod': {
        adminServerUrl: 'https://admin.edstart.com.au',
        adminWebUrl: 'https://admin.edstart.com.au',
    }
};

const knownHosts = {
    'front-plugin-prod.edstart.com.au': 'prod', // Old deployment location
    'front-plugin-beta.edstart.com.au': 'beta', // Old deployment location
    'front-plugin.edstart.com.au': 'prod', // New deployment location
    'front-plugin.beta.edstart.com.au': 'beta', // New deployment location
}

export const networkConfig = buildNetworkConfig();
console.log('Using network config', networkConfig);

function buildNetworkConfig() {
    const env = getEnv();
    console.log(`Env=${env}`);
    return settings[env] || settings['prod'];
}

function getEnv() {
    const params = (new URL(document.location)).searchParams;
    const serverEnv = params.get("serverEnv") || params.get("serverenv"); // Front automatically down-cases any urls
    if (serverEnv) {
        return serverEnv;
    }
    const host = window.location.hostname;
    const env = knownHosts[host];
    if (env) {
        return env;
    }
    return 'dev';
}