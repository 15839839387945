import React, {FC} from 'react';
import styled from 'styled-components';

export const Description: FC<{label: string}> = ({label, children,}) => {
  return (
    <Text>
      <Label>{label}</Label>:&nbsp;<Content>{children}</Content>
    </Text>
  );
}

const Text = styled.div`
  font-size: 14px;
  margin-bottom: 10px;
`;

const Label = styled.span`
  color: rgba(0, 0, 0, 0.85);
`;

const Content = styled.span`
  color: rgba(0, 0, 0 , 0.65);
`;