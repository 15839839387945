import React, {FC, useContext} from 'react';
import styled from 'styled-components';
import {networkConfig} from "../config/network";
import {PluginContext} from '../lib/plugin.context';


interface AdminLinkProps {
    type: string;
    id: string;
}

export const AdminLink: FC<AdminLinkProps> = ({children, type, id}) => (
    <Link href={`${networkConfig.adminWebUrl}/#/${type}/${id}`}>{children}</Link>
)

interface LinkProps {
    href: string;
}

export const Link: FC<LinkProps> = ({children, href}) => {
    const pluginContext = useContext(PluginContext);
    return <LinkStyle target="_blank" href={href} onClick={ (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        /**
         * Front stops anchor links from working, trying to force us to use their API:
         * https://dev.frontapp.com/docs/plugin-faq ("Why are my links not working?")
         * If we don't use the Front API, the links are opened in a semi-sandboxed state causing
         * things like PDF viewing to fail.
         *
         */
        console.log('Opening url', href);
        event.preventDefault();
        pluginContext.openUrl(href);
    }
    }>{children}</LinkStyle>
}

export const LinkStyle = styled.a`
  text-decoration: none;
  color: black;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;